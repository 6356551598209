export default function convertirADinero(numeroString) {
  // Verificar si el input es válido
  if (!numeroString || isNaN(numeroString)) {
      return "Error: El valor ingresado no es un número válido.";
  }

  // Convertir el string a número y redondear a 2 decimales
  const numero = parseFloat(numeroString).toFixed(2);

  // Separar los miles y agregar el símbolo de dólar
  const dineroFormateado = "$" + numero.replace(/\d(?=(\d{3})+\.)/g, "$&,");

  return dineroFormateado;
}
