function obtenerNombreMesAbreviado(numeroMes) {
    const mesesAbreviados = [
      "ENE", "FEB", "MAR", "ABR", "MAY", "JUN",
      "JUL", "AGO", "SEP", "OCT", "NOV", "DIC"
    ];
    return mesesAbreviados[numeroMes - 1];
  }
  
  export default function ObtenerSemanasDelCuatrimestre(cuatrimestreAño) {
    const cuatrimestreNum = parseInt(cuatrimestreAño.substring(1, 2));
    const año = parseInt(cuatrimestreAño.substring(2));
  
    const mesesPorCuatrimestre = [
      [1, 2, 3, 4],  // Cuatrimestre 1: Enero, Febrero, Marzo, Abril
      [5, 6, 7, 8],  // Cuatrimestre 2: Mayo, Junio, Julio, Agosto
      [9, 10, 11, 12] // Cuatrimestre 3: Septiembre, Octubre, Noviembre, Diciembre
    ];
  
    const semanas = [];
    const meses = mesesPorCuatrimestre[cuatrimestreNum - 1];
  
    meses.forEach(mes => {
      const diasEnMes = new Date(año, mes, 0).getDate();
      let dia = 1;
  
      while (dia <= diasEnMes) {
        const semana = [];
        for (let i = 0; i < 7; i++) {
          if (dia > diasEnMes) break;
          const mesAbreviado = obtenerNombreMesAbreviado(mes);
          semana.push(`${mesAbreviado}${Math.ceil(dia / 7)}`);
          dia += 7;
        }
        semanas.push(semana);
      }
    });
  
    return semanas.flat(); // Devuelve un solo array
  }
  