export default function FiltrarRegistrosPorCuatrimestre(registrosDB, cuatrimestre, enfoque) {
  // Obtener el año y número de cuatrimestre
  const cuatrimestreNum = parseInt(cuatrimestre.substring(1, 2));
  const year = parseInt(cuatrimestre.substring(2));

  // Definir la fecha de inicio y fin del cuatrimestre
  let fechaInicioCuatrimestre, fechaFinCuatrimestre;

  switch (cuatrimestreNum) {
    case 1:
      fechaInicioCuatrimestre = new Date(year, 0, 1);  // 01-01
      fechaFinCuatrimestre = new Date(year, 3, 30);    // 04-30
      break;
    case 2:
      fechaInicioCuatrimestre = new Date(year, 4, 1);  // 05-01
      fechaFinCuatrimestre = new Date(year, 7, 31);    // 08-31
      break;
    case 3:
      fechaInicioCuatrimestre = new Date(year, 8, 1);  // 09-01
      fechaFinCuatrimestre = new Date(year, 11, 31);   // 12-31
      break;
    default:
      throw new Error("Cuatrimestre inválido");
  }

  // Filtrar los registros que caen dentro del rango del cuatrimestre
  const registrosFiltrados = registrosDB.filter(registro => {
    const fechaRegistro = new Date(registro.fecha.replace(/(\d{2})\/(\d{2})\/(\d{4})/, '$2/$1/$3'));
    return fechaRegistro >= fechaInicioCuatrimestre && fechaRegistro <= fechaFinCuatrimestre;
  });

  
  if (enfoque == "Vida") {
    const registrosDelEnfoque = registrosFiltrados.filter(registro => registro.enfoque === enfoque);
    return registrosDelEnfoque;

  } else if (enfoque === "GMM") {
    const registrosDelEnfoque = [];
    
    registrosFiltrados.forEach(registro => {
      if(registro.enfoque && typeof registro.enfoque == "string") {
        if(registro.enfoque.includes(enfoque)) {
          registrosDelEnfoque.push(registro)
        }
      } 
    });
    
    return registrosDelEnfoque;
  }


  return registrosFiltrados;
}
