import React, { useEffect, useState } from "react"


// import
import Swal from 'sweetalert2';


// imports from mui x charts
import { BarChart } from '@mui/x-charts/BarChart';
import { LineChart } from '@mui/x-charts/LineChart';
import { PieChart } from "@mui/x-charts";

// Componentes
import AsesoresTable from "../../Components/AsesoresTable/AsesoresTable";
import RegistrosTable from "../../Components/RegistrosTable/RegistrosTable";
import SelectQuarter from "../../Components/SelectQuarter/SelectQuarter";

// Utils
import filtrarRegistrosPorAsesor from "../../Utils/FiltrarRegistrosPorAsesor";
import ObtenerTrimestreActual from "../../Utils/ObtenerTrimestreActual";
import FiltrarRegistrosPorTrimestre from "../../Utils/FiltrarRegistrosPorTrimestre";
import ContarEstatus from "../../Utils/ContarEstatus";
import ContarPagadosPorSemana from "../../Utils/ContarPagadosPorSemana";
import ContarPagadosYEntrevistasPorAsesor from "../../Utils/ContarPagadosYEntrevistasPorAsesor";



// styles
import "./Dashboard.scss"
import ContarPresupuestosPorSemana from "../../Utils/ContarPresupuestosPorSemana";
import SelectPagados from "../../Components/SelectPagados/SelectPagados";
import PagadosCharts from "../../Components/PagadosCharts/PagadosCharts";



export default function ({ }) {
  //Charts Style palette
  const chartsPalette = [
    "#004080", // Azul Marino
    "#0059b3", // Azul Real
    "#1f77b4", // Azul Celeste
    "#66b2ff", // Azul Claro
    "#99ccff", // Azul Pastel
    "#cceeff", // Azul Suave
    "#003366", // Azul Oscuro
    "#0073e6", // Azul Medio
    "#3399ff", // Azul Vivo
    "#80bfff"  // Azul Brillante
  ];

  //Conexión con registros DB
  const SteinStore = require("stein-js-client");
  const APIRegistrosDB = new SteinStore(process.env.REACT_APP_REGISTROS_API);
  const APIAsesoresDB = new SteinStore(process.env.REACT_APP_MIEMBROS_API);


  //Variables para ibases de datos completas y ya filtradas para cada grafica
  const [RegistrosDB, setRegistrosDB] = useState(null)
  const [RegistrosTrimestrales, setRegistrosTrimestrales] = useState([])

  const [AsesoresDB, setAsesoresDB] = useState(null)

  //Estados de funcionamiento de gráficas generales
  const [vsXAsesor, setvsXAsesor] = useState(null)
  const [ConteoDeRegistrosPorSemanaDB, setConteoDeRegistrosPorSemanaDB] = useState(null)
  const [ConteoDeProductosDB, setConteoDeProductosDB] = useState(null)
  const [ConteoDePresupuestos, setConteoDePresupuestos] = useState(null)



  //Estados de funcionamiento para analizar un asesor individualmente
  const [analizarAsesor, setAnalizarAsesor] = useState(null)
  const [analizarAsesorRegistros, setAnalizarAsesorRegistros] = useState([])
  const [analizarAsesorProductos, setAnalizarAsesorProductos] = useState([])
  const [analizarAsesorRegistrosPorSemana, setAnalizarAsesorRegistrosPorSemana] = useState([])
  const [analizarAsesoronteoDePresupuestos, setAnalizarAsesorConteoDePresupuestos] = useState([])
  // const [analizarAsesorPorcentajes, setAnalizarAsesorPorcentajes] = useState([])

  const [filtroPagados, setFiltroPagados] = useState("Integrales")
  const [filtroPagadosAsesor, setFiltroPagadosAsesor] = useState("Integrales")


  //filtro 
  const [fecha_a_medir, setFecha_a_medir] = useState(ObtenerTrimestreActual())


  // Funcionalidad del dashboard, activamos pestaña de carga
  const [loading, setLoading] = useState(true)
  const [initRender, setInitRender] = useState(1)




  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [paginatedAsesores, setPaginatedAsesores] = useState([]);
  const [paginatedPagados, setPaginatedPagados] = useState([]);
  const [paginatedCitas, setPaginatedCitas] = useState([]);
  const itemsPerPage = 10;





  const handlePreviousPage = () => {
    setCurrentPage(prevPage => Math.max(prevPage - 1, 1));
  };

  const handleNextPage = () => {
    setCurrentPage(prevPage => Math.min(prevPage + 1, totalPages));
  };







  useEffect(() => {
    if (vsXAsesor && vsXAsesor.Asesores) {
      const totalItems = vsXAsesor.Asesores.length;
      setTotalPages(Math.ceil(totalItems / itemsPerPage));
      setCurrentPage(1); // Reset to first page when data changes
    }
  }, [vsXAsesor]);

  useEffect(() => {
    if (vsXAsesor && vsXAsesor.Asesores) {
      const startIndex = (currentPage - 1) * itemsPerPage;
      const endIndex = startIndex + itemsPerPage;
      setPaginatedAsesores(vsXAsesor.Asesores.slice(startIndex, endIndex));
      setPaginatedPagados(vsXAsesor.Pagados.slice(startIndex, endIndex));
      setPaginatedCitas(vsXAsesor.Citas.slice(startIndex, endIndex));
    }
  }, [vsXAsesor, currentPage]);








  // Actualizaremos la data de las graficas casda que cambiemos la fecha a medir
  useEffect(() => {
    setInitRender(2)
  }, [fecha_a_medir])






  //if it is the fitrst render, now get the api for DBs
  useEffect(() => {

    if (initRender === 2) {

      APIRegistrosDB
        .read("Registros", {
          authentication: {
            username: process.env.REACT_APP_RECORDS_USERNAME,
            password: process.env.REACT_APP_RECORDS_PASSWORD
          }
        })
        .then(data => {
          let registrosDB = data;
          let registrosDelTrimestre = FiltrarRegistrosPorTrimestre(registrosDB, fecha_a_medir);

          // Registros trimestrales
          setRegistrosTrimestrales(registrosDelTrimestre)
          // // Raw data
          setRegistrosDB(registrosDB)

          // //total de registros por asesor
          setvsXAsesor(ContarPagadosYEntrevistasPorAsesor(registrosDelTrimestre))
          // //total de registros como dirección
          setConteoDeRegistrosPorSemanaDB(ContarPagadosPorSemana(registrosDelTrimestre, fecha_a_medir))
          // //total de registros por productos 
          setConteoDeProductosDB(ContarEstatus(registrosDelTrimestre))
          // //total de registros por productos 
          setConteoDePresupuestos(ContarPresupuestosPorSemana(registrosDelTrimestre, fecha_a_medir))


          APIAsesoresDB
            .read("Asesores", {
              authentication: {
                username: process.env.REACT_APP_MIEMBROS_API_USERNAME,
                password: process.env.REACT_APP_MIEMBROS_API_PASSWORD
              }
            })
            .then(data => {
              let asesoresDB = data;

              setAsesoresDB(asesoresDB)
              setLoading(false)
              setInitRender(3)

            })
            .catch(e => {
              console.error(e);
            });



        })

        .catch(e => {
          console.error(e);
        });
    }

  }, [initRender])



  useEffect(() => {
    //vamos a cambiar a 4 unicamente cuando hayamos utilizado el select
    if (initRender === 4) {
      // //reseteamos todas las variables
      setRegistrosTrimestrales([])
      setvsXAsesor(null)
      setConteoDeRegistrosPorSemanaDB(null)
      setConteoDeProductosDB(null)
      setConteoDePresupuestos(null)
      setFiltroPagados("Integrales")

      // //Estados de funcionamiento para analizar un asesor individualmente
      setAnalizarAsesor(null)
      setAnalizarAsesorRegistros([])
      setAnalizarAsesorRegistrosPorSemana([])
      setAnalizarAsesorProductos([])
      setAnalizarAsesorConteoDePresupuestos([])
      setFiltroPagadosAsesor("Integrales")



      // //definimos ahora los nuevos registros del trimestr seleccionado
      let registrosDelTrimestre = FiltrarRegistrosPorTrimestre(RegistrosDB, fecha_a_medir);
      setRegistrosTrimestrales(registrosDelTrimestre)

      // //total de registros por asesor
      setvsXAsesor(ContarPagadosYEntrevistasPorAsesor(registrosDelTrimestre))
      // //total de registros por productos 
      setConteoDeProductosDB(ContarEstatus(registrosDelTrimestre))
      // //total de registros como dirección
      setConteoDeRegistrosPorSemanaDB(ContarPagadosPorSemana(registrosDelTrimestre, fecha_a_medir))
      // //total de registros por productos 
      setConteoDePresupuestos(ContarPresupuestosPorSemana(registrosDelTrimestre, fecha_a_medir))
    }

  }, [fecha_a_medir, initRender])





  // Cargando al ingresasr al dashboard, cuando carga las apis ya no
  useEffect(() => {
    if (loading) {
      Swal.fire({
        title: 'Cargando...',
        icon: 'info',
        allowOutsideClick: false,
        allowEscapeKey: false,
        allowEnterKey: false,
        showConfirmButton: false,
        showCloseButton: false,
        didOpen: () => {
          Swal.showLoading();
        }
      })
    } else {
      Swal.close()
      // Obtener el elemento div por su ID
      const miDiv = document.getElementById('dashboard');

      // Hacer que la página se desplace automáticamente hacia el div
      miDiv.scrollIntoView({ behavior: 'smooth' });
    }
  }, [loading])




  // Busca registros por Asesor
  useEffect(() => {
    if (analizarAsesor) {
      // Obtener el elemento div por su ID
      const miDiv = document?.getElementById('searcher');

      // Hacer que la página se desplace automáticamente hacia el div
      miDiv?.scrollIntoView({ behavior: 'smooth' });


      const DBfiltrada = filtrarRegistrosPorAsesor(RegistrosDB, analizarAsesor.uuid);


      setAnalizarAsesorRegistros(DBfiltrada)
      setAnalizarAsesorRegistrosPorSemana(ContarPagadosPorSemana(DBfiltrada, fecha_a_medir))
      setAnalizarAsesorProductos(ContarEstatus(DBfiltrada))
      setAnalizarAsesorConteoDePresupuestos(ContarPresupuestosPorSemana(DBfiltrada, fecha_a_medir))
      
    }


  }, [analizarAsesor])










  return (<React.Fragment>
    <div id="dashboard" style={{ height: "40px" }} />

    {
      !loading &&
      <section className="step-wrap-container-dashboard">
        <div className="step-container">

          <SelectQuarter quarter={fecha_a_medir} setQuarter={setFecha_a_medir} setInitRender={setInitRender} />
          <div style={{ height: "20px" }} />

          <h1 className="h1" style={{ textAlign: "center" }}>
            Por Asesor - Trimestre {fecha_a_medir}
          </h1>

          <div style={{ height: "25px" }} />

          <BarChart
            series={[
              { data: paginatedPagados, color: "#004080", label: "Pagados" },
              { data: paginatedCitas, color: "#66b2ff", label: "Citas" },
            ]}

            height={650}
            layout="horizontal"
            yAxis={[{
              data: paginatedAsesores,
              scaleType: 'band',
            }]}
            margin={{ left: 160, top: 50, bottom: 50, right: 10 }}
            colors={chartsPalette}
          />

          <div className="pagination" style={{ textAlign: 'center', marginTop: '20px' }}>
            <button onClick={handlePreviousPage} disabled={currentPage === 1}>
              Anterior
            </button>
            <span style={{ margin: '0 10px' }}>Página {currentPage} de {totalPages}</span>
            <button onClick={handleNextPage} disabled={currentPage === totalPages}>
              Siguiente
            </button>
          </div>

        </div>
      </section>
    }




    <div style={{ height: "40px" }} />




    <section className="step-wrap-container step-wrap-container-dashboard">

      <div className="step-container">

        <div style={{ height: "10px" }} />

        <h1 className="h1" style={{ textAlign: "center" }}>
          Dashboard ProCapita
        </h1>

        <div style={{ height: "25px" }} />


        {
          (
            loading
          ) ?
            <div className="dashboard-container-skeleton" style={{ height: "664px", display: "grid", placeItems: "center" }}>
              <h2 className="h2">Cargando Información...</h2>
            </div>

            :
            <div className="dashboard-container">
              <div className="chart1">
                <h3 className="h3">Por Asesor - Trimestre {fecha_a_medir}</h3>

                <BarChart
                  // dataset={pagadosYEntrevistasXAsesor}
                  series={[
                    { data: paginatedPagados.length > 5 ? paginatedPagados.slice(0, 5) : paginatedPagados, color: "#004080", label: "Pagados" },
                    { data: paginatedCitas.length > 5 ? paginatedCitas.slice(0, 5) : paginatedCitas, color: "#66b2ff", label: "Citas" },
                  ]}

                  height={300}
                  layout="horizontal"
                  yAxis={[{
                    data: paginatedAsesores.length > 5 ? paginatedAsesores.slice(0, 5) : paginatedAsesores,
                    scaleType: 'band',
                  }]}
                  margin={{ left: 200, top: 50, bottom: 50, right: 10 }}
                  colors={chartsPalette}
                />
              </div>












              <div className="chart2">
                <h3 className="h3">Estatus - Trimestre {fecha_a_medir}</h3>
                <PieChart
                  colors={chartsPalette}



                  series={[
                    {
                      data: ConteoDeProductosDB,
                      innerRadius: 30,
                      outerRadius: 120,
                      paddingAngle: 2,
                      cornerRadius: 5,
                      startAngle: 0,
                      endAngle: 360,
                      highlightScope: { faded: 'global', highlighted: 'item' },
                      faded: { innerRadius: 30, additionalRadius: -30, color: 'gray' },
                    },
                  ]}



                  width={600}
                  height={300}
                />

              </div>





              <PagadosCharts
                RegistrosDB={RegistrosDB}

                fecha_a_medir={fecha_a_medir}
                chartsPalette={chartsPalette}
                ConteoDeRegistrosPorSemanaDB={ConteoDeRegistrosPorSemanaDB}
                ConteoDePresupuestos={ConteoDePresupuestos}

                filtroPagados={filtroPagados}
                setFiltroPagados={setFiltroPagados}
              />
            </div>
        }































      </div>
    </section>
    {
      !loading &&
      <div className="step-wrap-container-searcher">
        <h1 className="h1">Analisis individual</h1>
        <AsesoresTable data={AsesoresDB} onAsesorClick={setAnalizarAsesor} />
      </div>
    }



    {
      analizarAsesor &&
      <>
        <div id="searcher" style={{ height: "40px" }} />

        <section className="step-wrap-container step-wrap-container-dashboard">

          <div className="step-container">

            <div style={{ height: "40px" }} />

            <h1 className="h1" style={{ textAlign: "center" }}>
              Desempeño del asesor
            </h1>

            <div style={{ height: "25px" }} />














            <div className="dashboard-container">
              <div className="chart1">
                <h3 className="h3">Pagados - Trimestre {fecha_a_medir}</h3>
                <div style={{ height: "20px" }} />
                <RegistrosTable data={analizarAsesorRegistros.filter(registro => registro.estatus == "Pagado")} small />
              </div>












              <div className="chart2">
                <h3 className="h3">Estatus - Trimestre {fecha_a_medir}</h3>
                <PieChart
                  colors={chartsPalette}



                  series={[
                    {
                      data: analizarAsesorProductos,
                      innerRadius: 30,
                      outerRadius: 120,
                      paddingAngle: 2,
                      cornerRadius: 5,
                      startAngle: 0,
                      endAngle: 360,
                      highlightScope: { faded: 'global', highlighted: 'item' },
                      faded: { innerRadius: 30, additionalRadius: -30, color: 'gray' },
                    },
                  ]}




                  width={600}
                  height={300}
                />
              </div>










              <PagadosCharts
                RegistrosDB={RegistrosDB}
                
                fecha_a_medir={fecha_a_medir}
                chartsPalette={chartsPalette}

                ConteoDeRegistrosPorSemanaDB={analizarAsesorRegistrosPorSemana}
                ConteoDePresupuestos={analizarAsesoronteoDePresupuestos}
                // me parece que de esta manera ya funciona porque las variables son las mismas, debo revisar que dentro del componentee las modificaciones que estoy haciendo no tengan que ver con las graficas iniciales
                filtroPagados={filtroPagadosAsesor}
                setFiltroPagados={setFiltroPagadosAsesor}

                analizarAsesor={analizarAsesor}
              />





            </div>






























          </div>
        </section>
      </>
    }
  </React.Fragment>
  )
}