import React from 'react';

import './SelectPagados.scss'


// // Array de opciones de trimestres
// const generateQuarterOptions = () => {
//   const options = [];
//   const years = [2023, 2024, 2025];

//   years.forEach(year => {
//     for (let quarter = 1; quarter <= 4; quarter++) {
//       options.push({ label: `${quarter}Q${year}`, value: `${quarter}Q${year}` });
//     }
//   });

//   return options;
// };

// const quarterOptions = generateQuarterOptions();

// Componente SelectPagados
const SelectPagados = ({ filtroPagados, setFiltroPagados }) => {
  return (
    <select className='SelectPagados' value={filtroPagados} onChange={(e) => {setFiltroPagados(e.target.value)}}>
      <option value="Integrales">Integrales</option>
      <option value="Vida">Vida</option>
      <option value="GMM">GMM</option>
      
      
      
      {/* {quarterOptions.map(option => (
        <option key={option.value} value={option.value}>
          {option.label}
        </option>
      ))} */}
    </select>
  );
};

export default SelectPagados;
