import React from 'react';
import './SelectFourMonths.scss'

// Función para generar opciones de cuatrimestres
const generateFourMonthOptions = () => {
  const options = [];
  const years = [2024, 2025];

  years.forEach(year => {
    for (let cuatrimestre = 1; cuatrimestre <= 3; cuatrimestre++) {
      options.push({ label: `C${cuatrimestre}${year}`, value: `C${cuatrimestre}${year}` });
    }
  });

  return options;
};

const fourMonthOptions = generateFourMonthOptions();

// Componente SelectFourMonths
const SelectFourMonths = ({ cuatrimestre, setCuatrimestre }) => {
  return (
    <select className='SelectFourMonths' value={cuatrimestre} onChange={(e) => { setCuatrimestre(e.target.value) }}>
      {fourMonthOptions.map(option => (
        <option key={option.value} value={option.value}>
          {option.label}
        </option>
      ))}
    </select>
  );
};

export default SelectFourMonths;
