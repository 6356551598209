import React, { useState, useEffect } from "react"

// components
import { LineChart } from "@mui/x-charts"

// utils
import ContarPagadosPorSemana from "../../Utils/ContarPagadosPorSemana";
import FiltrarRegistrosPorTrimestre from "../../Utils/FiltrarRegistrosPorTrimestre";
import ContarPresupuestosPorSemana from "../../Utils/ContarPresupuestosPorSemana";
import SelectPagados from "../SelectPagados/SelectPagados";
import SelectFourMonths from "../SelectFourMonths/SelectFourMonths";
import FiltrarRegistrosPorCuatrimestre from "../../Utils/FiltrarRegistrosPorCuatrimestre";
import ContarPagadosPorSemanaCuatrimestre from "../../Utils/ContarPagadosPorSemanaCuatrimestre";
import ContarPresupuestosPorSemanaCuatrimestre from "../../Utils/ContarPresupuestosPorSemanaCuatrimestre";
import filtrarRegistrosPorAsesor from "../../Utils/FiltrarRegistrosPorAsesor";


export default function ({
  RegistrosDB,

  fecha_a_medir,
  chartsPalette,
  ConteoDeRegistrosPorSemanaDB,
  ConteoDePresupuestos,

  filtroPagados,
  setFiltroPagados,

  analizarAsesor
}) {

  
    // Función para obtener el cuatrimestre actual
    const getCurrentFourMonth = () => {
      const date = new Date();
      const year = date.getFullYear();
      const month = date.getMonth() + 1;
  
      let cuatrimestre;
      if (month >= 1 && month <= 4) {
        cuatrimestre = 1;
      } else if (month >= 5 && month <= 8) {
        cuatrimestre = 2;
      } else {
        cuatrimestre = 3;
      }
  
      return `C${cuatrimestre}${year}`;
    };

    
  const [currentPagados, setCurrentPagados] = useState([])
  const [currentPresupuestosPagados, setCurrentPresupuestosPagados] = useState([])

  const [cuatrimestre, setCuatrimestre] = useState(getCurrentFourMonth())



  useEffect(() => {
    if (RegistrosDB) {
      let _RegistrosDB = RegistrosDB;

      if(analizarAsesor) {
        _RegistrosDB = filtrarRegistrosPorAsesor(_RegistrosDB, analizarAsesor.uuid);
      }

      if (filtroPagados == "Integrales") {
        let registrosDelTrimestre = FiltrarRegistrosPorTrimestre(_RegistrosDB, fecha_a_medir);
        setCurrentPagados(ContarPagadosPorSemana(registrosDelTrimestre, fecha_a_medir))
        setCurrentPresupuestosPagados(ContarPresupuestosPorSemana(registrosDelTrimestre, fecha_a_medir))

      }
      else if (filtroPagados == "Vida") {
        let registrosDelTrimestre = FiltrarRegistrosPorTrimestre(_RegistrosDB, fecha_a_medir, "Vida");
        setCurrentPagados(ContarPagadosPorSemana(registrosDelTrimestre, fecha_a_medir))
        setCurrentPresupuestosPagados(ContarPresupuestosPorSemana(registrosDelTrimestre, fecha_a_medir))
        
      } else if (filtroPagados == "GMM") {
        let registrosDelCuatrimestre = FiltrarRegistrosPorCuatrimestre(_RegistrosDB, cuatrimestre, "GMM")
        
        setCurrentPagados(ContarPagadosPorSemanaCuatrimestre(registrosDelCuatrimestre, cuatrimestre))
        setCurrentPresupuestosPagados(ContarPresupuestosPorSemanaCuatrimestre(registrosDelCuatrimestre, cuatrimestre))

        
      }

    }

  }, [filtroPagados, cuatrimestre, RegistrosDB, analizarAsesor])


  useEffect(() => {
    if (filtroPagados == "GMM") {
      setCuatrimestre(getCurrentFourMonth())
    }
  }, [filtroPagados])


  return (
    <React.Fragment>
      <div className="chart3">
        <h3 className="h3">Pagados {filtroPagados} - {
          filtroPagados == "GMM" ?
            "Cuatrimestre " + cuatrimestre
            : "Trimestre " + fecha_a_medir
        }
        </h3>
        <div className="lineChartContainer">
          <LineChart
            colors={chartsPalette}
            grid={{ horizontal: true }}
            dataset={currentPagados}

            xAxis={[{
              dataKey: "n_semana",
              valueFormatter: (value => currentPagados[value - 1]?.semana)
            }]}
            series={[
              {
                dataKey: "total_pagados",
                area: true,
                color: "#004080"
              },
            ]}

            width={600}
            height={300}
          />
        </div>
      </div>



      <div className="chart4">
        <h3 className="h3">Presupuestos Pagados {filtroPagados} - {
          filtroPagados == "GMM" ?
            "Cuatrimestre " + cuatrimestre
            : "Trimestre " + fecha_a_medir
        }
        </h3>
        <div className="lineChartContainer">
          <LineChart
            colors={chartsPalette}
            grid={{ horizontal: true }}
            dataset={currentPresupuestosPagados}


            // xAxis={[{ data: ["Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio", "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre"]}]}
            xAxis={[{
              dataKey: "n_semana",
              valueFormatter: (value => currentPresupuestosPagados[value - 1]?.semana)
            }]}
            series={[
              {
                dataKey: "total_presupuesto",
                area: true,
                color: "#004080"
              },
            ]}



            width={600}
            height={300}
          // margin={{left: 70}}
          />
        </div>
      </div>



      <div className="SelectPagadosContainer">
        <SelectPagados
          filtroPagados={filtroPagados}
          setFiltroPagados={setFiltroPagados}
        />

        {
          filtroPagados == "GMM" &&
          <SelectFourMonths
            cuatrimestre={cuatrimestre}
            setCuatrimestre={setCuatrimestre}
          />
        }
      </div>
    </React.Fragment>
  )
}